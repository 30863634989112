import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./i18n";
import vant from "vant";
import Vue3Marquee from "vue3-marquee";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import './main.css';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Shanghai");

import "vant/lib/index.css";
const app = createApp(App);
app.use(store).use(router).use(i18n).use(vant).use(Vue3Marquee);
app.mount("#app");
